









import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class UserDashboard extends Vue {}
